import { createListenerMiddleware } from '@reduxjs/toolkit';
import { fetchApplicationsAction } from 'APP/feature/applications/applicationsSliceActions';
import { postOfferAction } from 'APP/feature/offerPage/offerSliceActions';

const acceptancePageListenerMiddleware = createListenerMiddleware();

acceptancePageListenerMiddleware.startListening({
    actionCreator: postOfferAction.fulfilled,
    effect: async (action, listenerApi) => {
        listenerApi.dispatch(fetchApplicationsAction());
    },
});

export default acceptancePageListenerMiddleware;
